import React, { useState, useContext, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Parallax, Navigation } from 'swiper';
import ModalVideo from 'react-modal-video';
import pic1 from '../assets/images/main-slider/mainside1.png';
import pic2 from '../assets/images/main-slider/mainside2.png';
import pic3 from '../assets/images/main-slider/mainside3.png';
import Working from '../components/Working';
import VideoCounter from '../components/VideoCounter';
import PopularService from '../components/PopularService';
import Blog from '../components/Blog';
import { IMAGES } from '../constants/theme';
import Projects from '../components/Projects';
import Testimonial1 from '../components/Testimonial1';
import Testimonial2 from '../components/Testimonial2';
import Faq from '../components/Faq';
import Progress from '../components/Progress';
//import Blogshow from './Blogshow';
import pic4 from '../assets/images/main-slider/mainSample.png';


SwiperCore.use([Navigation,  Autoplay]);


const slideImg = [
    { text: "Creative Design", img: pic1 },
    { text: "Design and Build", img: pic2 },
    { text: "Sustainability First", img: pic3 },
    { text: "After-Sales Care", img: pic4 },
];
const Home = () => {
    const swiperRef = React.useRef(null);    
    const [isOpen, setOpen] = useState(false);

    const firstItem = slideImg[0];
    const secondItem = slideImg[1];
    const thirdItem = slideImg[2];
    const fourthItem = slideImg[3];
   
    return (
        <>
            <div className="page-content bg-white">
                <div className="slidearea">
                    <div className="side-contact-info">
                        <ul>
                            <li><i className="fas fa-phone-alt"></i> +61 (08) 82579123</li>
                            <li><i className="fas fa-envelope"></i> info@cyberidge.com.au</li>
                        </ul>
                    </div>
                    <div className="silder-one">
                        <Swiper className="swiper-container main-silder-swiper"

                                ref={swiperRef}
                                slidesPerView={1}
                                parallax={true}
                                speed={2000}
                                loop={true}
                                autoplay={{delay: 3000}}
                                pagination={{
                                    el: ".pagination-styled",
                                    type: "fraction",
                                }}
                                modules={[Pagination, Parallax, Navigation, Autoplay]}
                        >


                            {

                                <SwiperSlide className="swiper-slide" tag='div'>
                                    <div className="silder-img overlay-black-light">
                                        <img src={firstItem.img} data-swiper-parallax="30%" alt=""/>
                                    </div>
                                    <div className="silder-content" data-swiper-parallax="-40%">
                                        <div className="inner-content">
                                            <h6 className="sub-title">{firstItem.text}</h6>
                                            <h1 className="title">We Create </h1>
                                            <h3 className="title-small">Innovative Design<br/>Concepts</h3>
                                            <Link to="/about-us"
                                                  className="btn shadow-primary btn-light btn-rounded btn-ov-secondary">READ
                                                MORE <i className="m-l10 fas fa-caret-right"></i></Link>
                                        </div>
                                        <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                    </div>
                                </SwiperSlide>
                            }

                            {

                                <SwiperSlide className="swiper-slide" tag='div'>
                                    <div className="silder-img overlay-black-light">
                                        <img src={secondItem.img} data-swiper-parallax="30%" alt=""/>
                                    </div>
                                    <div className="silder-content" data-swiper-parallax="-40%">
                                        <div className="inner-content">
                                            <h6 className="sub-title">{secondItem.text}</h6>
                                            <h1 className="title">We Deliver</h1>
                                            <h3 className="title-small">One-Stop Service, <br/>From Concept to Completion</h3>
                                            <Link to="/about-us"
                                                  className="btn shadow-primary btn-light btn-rounded btn-ov-secondary">READ
                                                MORE <i className="m-l10 fas fa-caret-right"></i></Link>
                                        </div>
                                        <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                    </div>
                                </SwiperSlide>
                            }


                            {

                                <SwiperSlide className="swiper-slide" tag='div'>
                                    <div className="silder-img overlay-black-light">
                                        <img src={thirdItem.img} data-swiper-parallax="30%" alt=""/>
                                    </div>
                                    <div className="silder-content" data-swiper-parallax="-40%">
                                        <div className="inner-content">
                                            <h6 className="sub-title">{thirdItem.text}</h6>
                                            <h1 className="title">We Build</h1>
                                            <h3 className="title-small">A Greener Future<br/>Together</h3>
                                            <Link to="/about-us"
                                                  className="btn shadow-primary btn-light btn-rounded btn-ov-secondary">READ
                                                MORE <i className="m-l10 fas fa-caret-right"></i></Link>
                                        </div>
                                        <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                    </div>
                                </SwiperSlide>
                            }


                            {

                                <SwiperSlide className="swiper-slide" tag='div'>
                                    <div className="silder-img overlay-black-light">
                                        <img src={fourthItem.img} data-swiper-parallax="30%" alt=""/>
                                    </div>
                                    <div className="silder-content" data-swiper-parallax="-40%">
                                        <div className="inner-content">
                                            <h6 className="sub-title">{fourthItem.text}</h6>
                                            <h1 className="title">We Support</h1>
                                            <h3 className="title-small">Reliable Service<br/>Beyond Completion</h3>
                                            <Link to="/services"
                                                  className="btn shadow-primary btn-light btn-rounded btn-ov-secondary">READ
                                                MORE <i className="m-l10 fas fa-caret-right"></i></Link>
                                        </div>
                                        <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                    </div>
                                </SwiperSlide>
                            }


                        </Swiper>


                        <div className="slider-one-pagination">
                            <div
                                className="btn-prev swiper-button-prev1 swiper-button-white"
                                onClick={(e) => swiperRef.current.swiper.slidePrev()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: '70px',
                                    marginRight: '20px',
                                }}
                            >
                                <span style={{marginRight: '30px'}}>
                                    <i className="las la-long-arrow-alt-left"></i>
                                </span>
                                <span>PREV</span>
                            </div>
                            <div className="swiper-pagination pagination-styled" style={{margin: '0 0px'}}></div>
                            <div
                                className="btn-next swiper-button-next1 swiper-button-white"
                                onClick={(e) => swiperRef.current.swiper.slideNext()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    width: '70px',
                                }}
                            >
                                <span>NEXT</span>
                                <span style={{marginLeft: '30px'}}>
                                    <i className="las la-long-arrow-alt-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="section-full content-inner about-bx2" style={{
                    backgroundImage: `url(${IMAGES.background2})`,
                    backgroundPosition: 'right bottom',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    paddingBottom: '0%'
                }}>
                    <div className="container">
                        <Working/>
                    </div>
                </section>
                {/* <section className="dz-content-bx style-3">
                    <VideoCounter  setOpen={setOpen}/>
                </section> */}
                <section className="content-inner-2" style={{
                    backgroundImage: `url(${IMAGES.background3})`,
                    backgroundPosition: 'left top',
                    backgroundSize: '100%',
                    backgroundRepeat: 'no-repeat',
                    paddingTop: '0%'
                }}>
                    <PopularService/>
                </section>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <section className="section-full content-inner overflow-hidden"
                         style={{
                             paddingTop: '0px',
                             backgroundImage: `url(${IMAGES.bg1})`,
                             backgroundPosition: "left top",
                             backgroundSize: "100%",
                             backgroundRepeat: "no-repeat",
                         }}>
                    <Faq/>
                </section>


                <section className="content-inner-2">
                    <div className="container">
                        <div className="row section-head-bx align-items-center">
                            <div className="col-md-8">
                                <div className="section-head style-1">
                                    <h2 className="sub-title text-primary">OUR PROJECTS</h2>
                                    {/* <h2 className="title">Our Latest Projects</h2> */}
                                </div>
                            </div>
                            <div className="col-md-4 text-start text-md-end mb-4 mb-md-0">
                                <Link to="/portfolio" className="btn-link">See All Projects <i
                                    className="fas fa-plus scale08"></i></Link>
                            </div>
                        </div>


                    </div>
                    <Projects style={{marginLeft: '10%', marginRight: '10%'}}/>
                </section>


                {/* <section className="content-inner-2" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                        <Testimonial1 />
                    </section> */}
                {/* <section className="content-inner-2">   
                    <Testimonial2 />
                </section> */}
                {/* <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Faq />
                </section> */}
                {/* <section className="section-full dz-content-bx style-2 text-white" >
                    <Progress />
                </section> */}
                {/* <section className="content-inner-1 bg-gray line-img" style={{ backgroundImage: `url(images/background/bg2.png)`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Blogshow />
                </section> */}
            </div>
            <ModalVideo channel="youtube" autoplay
                        isOpen={isOpen} videoId="sNCv3_NTNtU" onClose={() => setOpen(false)}
            />
        </>

    )
}

export default Home
