import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import LargeSidebar from '../components/LargeSidebar'
import { Link } from 'react-router-dom'
import TeamMembers from './Team'
import OurValues from '../components/OurValues'

const testimonialBlog = [
    {img: IMAGES.blogGridPic2 },
    {img: IMAGES.blogGridPic1 },
]

const coments = [
    {name: "A", img: IMAGES.avatar },
    {changeStyle: "m-l40", name: "B", img: IMAGES.avatar1 },
    {name: "C", img: IMAGES.avatar2 },
]



const AboutUs = () => {
    return (
        <>

            <div className="page-content bg-white">
                <CommanBanner mainTitle="About Us" bgImage={IMAGES.bnr2} />
                <div className="content-inner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
                                <div className="dz-card blog-single style-1">
                                    <div className="dz-media">
                                        <img src={IMAGES.blogGridPic2} alt="" />
                                    </div>
                                    <div className="dz-info">

                                        <h2 className="dz-title">Leading The Way In Sustainable Construction</h2>
                                        <div className="dz-post-text">
                                            <p style={{textAlign: 'justify'}}>For property developers and investors seeking high-quality and sustainable construction services, Cyberidge Construction is a reliable company offering the most efficient and eco-friendly building solutions. Our commitment to sustainable practices, quality craftsmanship, and innovative construction technologies ensures that every project meets the highest standards.</p>
                                            <blockquote className="block-quote style-1">
                                                <p>We Live Green, And Let's Build Green.</p>
                            
                                            </blockquote>
                                            <div className="alignleft w-50">
                                                <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                            </div>
                                            <h2 className="dz-title">Our Story</h2>
                                            <p style={{textAlign: 'justify'}}>As a proud member of the DDDI Group, Cyberidge Construction has a strong foundation in delivering high-quality construction services, backed by years of industry expertise. Our focus is on providing property developers and investors with reliable, efficient, and comprehensive building solutions.</p>
                                            <p style={{textAlign: 'justify'}}>With a team of experienced professionals and a track record of successful projects, we specialise in managing complex construction projects from start to finish, ensuring timely delivery and top-tier craftsmanship. Our commitment to excellence drives every aspect of our work, from initial planning and design to the final stages of construction.</p>
                                            <p style={{textAlign: 'justify'}}>At Cyberidge Construction, we offer more than just building services; we provide a seamless, integrated experience by collaborating with other DDDI Group members—Cyberate Investments, Cyberate Technologies, and Cyberate Finance. This collaboration allows us to deliver full-service solutions, covering everything from investment consulting and financing to technology integration and construction execution. Our clients benefit from a streamlined process that ensures efficiency and success in every project.</p>
                                            
                                            <h2 className="dz-title">Our Mission</h2>
                                            <p style={{textAlign: 'justify'}}>To deliver high-quality and sustainable construction services, ensuring efficiency and environmental responsibility in every project we undertake.</p>
                                           
                                            <h2 className="dz-title">Future Vision</h2>
                                            <p style={{textAlign: 'justify'}}>To be the most reliable and environmentally responsible construction company, admired for our commitment to sustainability, quality craftsmanship, and innovative project management.</p>
                                            <blockquote className="block-quote style-1">
                                                <p style={{textAlign: 'justify'}}>Our Future Vision Is A Green Future.</p>
                            
                                            </blockquote>
                                        </div>
                                        {/*<div className="post-footer">
                                            {/* <div className="post-tags">
                                                <strong>Tags:</strong>
                                                <Link rel="tag">Construction</Link>
                                            </div> */}
                                            {/* <div className="dz-share-post">
                                                <h5 className="title">Share :</h5>
                                                <ul className="dz-social-icon">
                                                    <li><Link target="_blank"><i className="fab fa-facebook-f"></i></Link></li>
                                                    <li><Link target="_blank"><i className="fab fa-twitter"></i></Link></li>
                                                    <li><Link target="_blank"><i className="fab fa-linkedin"></i></Link></li>
                                                </ul>
                                            </div> 
                                        </div>*/}
                                        
                                       
                                    </div>
                                    
                                </div>
                                {/* <div className="author-box blog-user m-b60">
                                    <div className="author-profile-info">
                                        <div className="author-profile-pic">
                                            <img src={IMAGES.testimonial} alt="Profile Pic" />
                                        </div>
                                        <div className="author-profile-content">
                                            <h6>John Doe</h6>
                                            <p>We woke reasonably late following the feast and free flowing wine the night before. After gathering ourselves and our packs, we headed down to our home.</p>
                                            <ul className="list-inline m-b0">
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fas fa-globe"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link target="_blank" className="btn-link">
                                                        <i className="fab fa-youtube"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
{/* 
                                <div className="row extra-blog style-1">
                                    <div className="col-md-12">
                                        <div className="widget-title">
                                            <h4 className="title"> Related Blogs</h4>
                                            <div className="dz-separator style-1 text-primary mb-0"></div>
                                        </div>
                                    </div>
                                    {
                                        testimonialBlog.map((i, index) => (
                                            <div className="col-md-6" key={index}>
                                                <div className="dz-card blog-grid style-1 aos-item">
                                                    <div className="dz-media">
                                                        <Link to="/blog-details"><img src={i.img} alt="" /></Link>
                                                    </div>
                                                    <div className="dz-info">
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date">
                                                                    <strong>26</strong>
                                                                    <span>Apr</span>
                                                                </li>
                                                                <li className="post-user">By <Link>Willam Jiang</Link></li>
                                                            </ul>
                                                        </div>
                                                        <h5 className="dz-title"><Link to="/blog-details">Modern Family House.</Link></h5>
                                                        <div className="read-more">
                                                            <Link className="btn btn-primary btn-rounded btn-sm hover-icon"><span>Read More </span><i className="fas fa-arrow-right"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div> */}
{/* 
                                <div className="clear" id="comment-list">
                                    <div className="comments-area style-1" id="comments">
                                        <div className="widget-title">
                                            <h4 className="title">8 Comments</h4>
                                            <div className="dz-separator style-1 text-primary mb-0"></div>
                                        </div>
                                        <div className="clearfix">
                                            <ol className="comment-list">
                                                {
                                                    coments.map((i, index) => (
                                                        <li className={`comment ${i.changeStyle}`} key={index}>
                                                            <div className="comment-body">
                                                                <div className="comment-author vcard">
                                                                    <img className="avatar photo" src={i.img} alt="" />
                                                                    <cite className="fn">John Doe</cite>
                                                                </div>
                                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                                <div className="reply">
                                                                    <Link className="comment-reply-link">
                                                                        <i className="fa fa-reply"></i>Reply</Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </ol>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="comment-respond style-1" id="respond">
                                    <div className="widget-title">
                                        <h4 className="title">Leave Your Comment</h4>
                                        <div className="dz-separator style-1 text-primary mb-0"></div>
                                        <Link style={{ display: 'none' }} id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link>
                                    </div>
                                    <form className="comment-form" id="commentform" method="post">
                                        <p className="comment-form-author">
                                            <label htmlFor="author">Name <span className="required">*</span></label>
                                            <input type="text" name="Author" placeholder="Author" id="author" />
                                        </p>
                                        <p className="comment-form-email">
                                            <label htmlFor="email">Email <span className="required">*</span></label>
                                            <input type="text" placeholder="Email" name="email" id="email" />
                                        </p>
                                        <p className="comment-form-author w-100">
                                            <label htmlFor="author">Subtitale <span className="required">*</span></label>
                                            <input type="text" name="Author" placeholder="Subtitale" id="subtitale" />
                                        </p>
                                        <p className="comment-form-comment">
                                            <label htmlFor="comment">Comment</label>
                                            <textarea rows="8" name="comment" placeholder="Comment" id="comment"></textarea>
                                        </p>
                                        <p className="form-submit">
                                            <button type="submit" className="btn shadow-primary btn-primary btn-icon btn-rounded" id="submit">POST NOW
                                                <i className="fas fa-caret-right ms-1"></i>
                                            </button>
                                        </p>
                                    </form>

                                    
                                </div> */}
                            </div>
                            {/* <LargeSidebar /> */}
                        </div>
                    </div>
                </div>
                    <div style={{margin:'0px', padding:'0px', border: '0px'}}>
                        <section
                        className="section-full content-inner overflow-hidden"
                        style={{
                            paddingTop:'0px',
                            backgroundImage: `url(${IMAGES.bg1})`,
                            backgroundPosition: "left top",
                            backgroundSize: "100%",
                            backgroundRepeat: "no-repeat",
                        }}
                        >
                        <OurValues />
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <div
                            className="heading_container"
                            style={{ textAlign: "center", marginBottom: "20px" }}
                        >
                            <h2
                            className="dz-title ml2"
                            style={{
                                fontFamily: "Arial Black, Gadget, sans-serif",
                                fontSize: "46px",
                                fontWeight: "bold",
                                lineHeight: "1.2",
                            }}
                            >
                            <span>Our Team</span>
                            <br />
                            </h2>
                        </div>
                        <TeamMembers />
                    </section>
                </div>
            </div>

        </>)
}

export default AboutUs