import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { IMAGES } from '../constants/theme'
import { motion } from 'framer-motion'


const accordian = [
    { id: 1, key: 0, title: " How do I start a construction project with your company?", data: "The process begins with an initial consultation where we discuss your vision, budget, and timeline. Our team will guide you through planning, design, and the approval stages before construction begins. We are dedicated to providing professional, one-stop services tailored to your needs." },
    { id: 2, key: 1, title: " Sed vehicula ullamcorper odio ac pellentesque ?", data: "The timeline depends on the project size, complexity, and approval processes. However, at Cyberidge Construction, we prioritise efficiency and are renowned for delivering projects swiftly without compromising on quality. After the initial consultation, we provide a detailed schedule and keep clients updated throughout, ensuring timely completion that meets your expectations." },
    { id: 3, key: 2, title: " Sed vehicula ullamcorper odio ac pellentesque ?", data: "We prioritise premium-quality, durable, and environmentally sustainable materials, carefully selected to suit the unique requirements of each project. Our approach emphasises long-term value, energy efficiency, and environmental responsibility, ensuring every structure meets the highest standards of quality and sustainability." },
    { id: 4, key: 3, title: " What sustainable building practices do we use ?", data: "Yes, all our projects include a comprehensive warranty period, offering assurance and confidence to our clients. Furthermore, we provide dedicated after-sales support to resolve maintenance concerns efficiently, ensuring your property retains its quality and value for years to come.\n" },
    { id: 5, key: 4, title: " How do our green projects benefit the environment ?", data: "Our dedication to unrivalled workmanship, sustainable practices, and client satisfaction distinguishes us within the industry. With a highly skilled team and advanced technologies, we deliver exceptional results meticulously tailored to your vision and requirements." }
]




const Faq = () => {


    const firstItem=accordian[0];
    const secondItem=accordian[1];
    const thirdItem=accordian[2];
    const fourthItem=accordian[3];
    const fifthItem=accordian[4];

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <motion.div className="col-lg-6 m-b30 aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="300"
                        initial={{ opacity: 0, x: "-100%" }}
                        whileInView={{ opacity: 1, x: "0%" }}
                        transition={{ duration: 1 }}
                    >
                        <div className="twentytwenty-img-area">
                            <div className="twentytwenty-container">
                                <img src={IMAGES.pic2_2} alt="" style={{width:'70%'}}/>
                            </div>
                        </div>
                    </motion.div>
                    <div className="col-lg-6 aos-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600">
                        <div className="section-head style-1">
                            <h6 className="sub-title text-primary">FAQ</h6>
                            <h2 className="title">Get Every Answer From Here</h2>
                        </div>
                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={firstItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${firstItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                How do I start a construction project with your company?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{firstItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={secondItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${secondItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                How long does it take to complete a construction project?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{secondItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={thirdItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${thirdItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                What materials do you use in construction?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{thirdItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>


                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fourthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fourthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                Do you provide warranties or after-sales support?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{fourthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fifthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fifthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                What sets your company apart from others in the industry?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{fifthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq