import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { IMAGES } from '../constants/theme'
import { motion } from 'framer-motion'


const accordian = [
    { id: 1, key: 0, title: " Sustainability ", data: "Committed to promoting green building and sustainable practices and reducing environmental impact through the use of environmentally friendly materials and technologies." },
    { id: 2, key: 1, title: " Quality ", data: "Insisting on high quality workmanship and excellence in project management to ensure that every project meets or exceeds our clients' expectations." },
    { id: 4, key: 3, title: " Innovation ", data: "Continuously innovate and adopt the latest building technologies and methods to enhance building efficiency and environmental performance." },
    { id: 5, key: 4, title: " Reliability ", data: " Being a trusted partner to our clients, ensuring projects are completed on time and on budget, and providing enduring support and service." }
]

const OurValues = () => {

    const firstItem=accordian[0];
    const secondItem=accordian[1];
    const thirdItem=accordian[2];
    const fourthItem=accordian[3];

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <motion.div className="col-lg-6 m-b30 aos-item" data-aos="fade-right" data-aos-duration="800" data-aos-delay="300"
                        initial={{ opacity: 0, x: "-100%" }}
                        whileInView={{ opacity: 1, x: "0%" }}
                        transition={{ duration: 1 }}
                    >
                        <div className="twentytwenty-img-area">
                            <div className="twentytwenty-container">
                                <img src={IMAGES.pic2_2} alt="" style={{width:'70%'}}/>
                            </div>
                        </div>
                    </motion.div>
                    <div className="col-lg-6 aos-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600" style={{marginTop: '-6%'}}>
                        <div className="section-head style-1">
                            <h2 className="title">Our Values</h2>
                        </div>
                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={firstItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${firstItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                    Sustainability
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{firstItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={secondItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${secondItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                    Quality
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{secondItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={thirdItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${thirdItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                    Innovation
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{thirdItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>


                        <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fourthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fourthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                                Reliability
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{fourthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion>

                        {/* <Accordion defaultActiveKey="0" className="dz-accordion accordion-sm">
                            {
                          
                                    <motion.div
                                        key={fifthItem}
                                        initial={{ opacity: 0, x: "100%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        transition={{ duration: 1 }}
                                    >
                                        <Accordion.Item eventKey={`${fifthItem.key}`}>
                                            <Accordion.Header className="accordion-header" id="headingOne">
                                            How do our green projects benefit the environment?
                                                <span className="toggle-close"></span>
                                            </Accordion.Header>
                                            <div id="collapseOne" className="accordion-collapse collapse show" >
                                                <Accordion.Body>
                                                    <p className="m-b0" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{fifthItem.data}</p>
                                                </Accordion.Body>

                                            </div>
                                        </Accordion.Item>
                                    </motion.div>
                                
                            }
                        </Accordion> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default OurValues