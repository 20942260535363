import React, { useState, useRef } from 'react'
import { IMAGES } from '../constants/theme'
import CommanBanner from '../elements/CommanBanner'
import { Swiper, SwiperSlide } from 'swiper/react';
import Progress from '../components/Progress'
import Faq from '../components/Faq'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import clearView from '../assets/images/upcomingProjectImage/clearView.jpg'
import darlington from '../assets/images/upcomingProjectImage/darlington.png'
import ingleFarm from '../assets/images/upcomingProjectImage/ingleFarm.jpg'
import geppeCross from '../assets/images/upcomingProjectImage/geppeCross.png'
import modburyHeights from '../assets/images/upcomingProjectImage/modburyHeights.png'
import pooraka from '../assets/images/upcomingProjectImage/pooraka.png'
import vallyview from '../assets/images/upcomingProjectImage/vallyView.png'
import magill from '../assets/images/upcomingProjectImage/magill.png'
import {Autoplay, Navigation, Pagination, Parallax} from "swiper";
import pooraka1 from '../assets/images/upcomingProjectImage/pooraka1.png'
import pooraka2 from '../assets/images/upcomingProjectImage/pooraka2.png'
import clearView1 from '../assets/images/upcomingProjectImage/clearView1.png'
import clearView2 from '../assets/images/upcomingProjectImage/clearView2.png'
import clearView3 from '../assets/images/upcomingProjectImage/clearView3.png'
import magill1 from '../assets/images/upcomingProjectImage/magill1.png'
import magill2 from '../assets/images/upcomingProjectImage/magill2.png'
import magill3 from '../assets/images/upcomingProjectImage/magill3.png'
import magill4 from '../assets/images/upcomingProjectImage/magill4.png'
import magill5 from '../assets/images/upcomingProjectImage/magill5.png'

const ImaGallery = [
    { id: 1, catagery: "architecture", img: IMAGES.galleryImg1 },
    { id: 2, catagery: "interiar design", img: IMAGES.galleryImg2 },
    { id: 3, catagery: "construction", img: IMAGES.galleryImg3 },
    { id: 4, catagery: "construction", img: IMAGES.galleryImg4 },
    { id: 5, catagery: "architecture", img: IMAGES.galleryImg5 },
    { id: 6, catagery: "interiar design", img: IMAGES.galleryImg6 },
    { id: 7, img: clearView},
    { id: 8, img: darlington},
    { id: 9, img: ingleFarm},
    { id: 10, img: geppeCross},
    { id: 11, img: modburyHeights},
    { id: 12, img: pooraka},
    { id: 13, img: vallyview},
    { id: 14, img: magill},
    { id: 15, img: pooraka1},
    { id: 16, img: pooraka2},
    { id: 17, img: clearView1},
    { id: 18, img: clearView2},
    { id: 19, img: clearView3},
    { id: 20, img: magill1},
    { id: 21, img: magill2},
    { id: 22, img: magill3},
    { id: 23, img: magill4},
    { id: 24, img: magill5},
]

const Portfolio = () => {
    const [item, setItem] = useState(ImaGallery);
    const [active, setActive] = useState(false);
    const fiterItemes = (filterArr) => {
        setActive(filterArr);
        let updateItemes = ImaGallery.filter((CurEle) => {
            return CurEle.catagery === filterArr;
        });
        setItem(updateItemes);
    }

    const [item1, setItem1] = useState(ImaGallery[0]);
    const [item2, setItem2] = useState(ImaGallery[1]);

    const swiperRef = React.useRef(null);


    return (
        <>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="Projects" bgImage={IMAGES.bnr9} />
                <section className="content-inner line-img overflow-hidden"
                         style={{marginLeft: '5%', marginRight: '10%'}}>
                    <div className="site-filters style-1 clearfix center">
                        <ul className="filters" data-toggle="buttons">
                            <li onClick={() => {
                                setItem(ImaGallery);
                                setActive(false)
                            }} className={`btn ${item === ImaGallery ? 'active' : undefined}`}
                            >
                                {/* <Link>All</Link> */}
                            </li>
                            <li
                                onClick={() => {
                                    fiterItemes('interiar design')
                                }}
                                className={`btn ${active === 'interiar design' ? 'active' : undefined}`}>
                                {/* <Link>Architecture</Link> */}
                            </li>
                            <li onClick={() => {
                                fiterItemes('construction')
                            }}
                                className={`btn ${active === 'construction' ? 'active' : undefined}`}
                            >
                                {/* <Link >Interior Design</Link> */}
                            </li>
                            <li onClick={() => {
                                fiterItemes('architecture')
                            }}
                                className={`btn ${active === 'architecture' ? 'active' : undefined}`}
                            >
                                {/* <Link>Construction</Link> */}
                            </li>
                        </ul>
                    </div>

                    <div className="section-head style-1 text-center">
                        <h6 className="sub-title text-primary">OUR PROJECTS</h6>
                        <h2 className="title">Our Latest Projects</h2>
                    </div>

                    <div className="dz-box overlay style-1"
                         style={{ marginLeft: '10%', maxWidth: '1920px', maxHeight: '750px'}}>
                        <div className="silder-one">
                            <Swiper className="swiper-container main-silder-swiper"

                                    ref={swiperRef}
                                    slidesPerView={1}
                                    parallax={true}
                                    speed={2000}
                                    loop={true}
                                    autoplay={{delay: 3000}}
                                    pagination={{
                                        el: ".pagination-styled",
                                        type: "fraction",
                                    }}
                                    modules={[Pagination, Parallax, Navigation, Autoplay]}
                            >

                                {
                                    <SwiperSlide className="swiper-slide" tag='div'>
                                        <div className="silder-img overlay-black-light">
                                            <img src={pooraka} data-swiper-parallax="30%" alt=""/>
                                        </div>
                                        <div className="silder-content" data-swiper-parallax="-40%">
                                            <div className="dz-info">
                                                <h6 className="title m-b15"
                                                    style={{fontSize: '50px', lineHeight: '2.0'}}><Link
                                                    to="/portfolio-details">Pooraka
                                                    <br></br>
                                                    <span>SA 5095</span></Link></h6>
                                            </div>
                                            <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                        </div>
                                    </SwiperSlide>
                                }

                                {
                                    <SwiperSlide className="swiper-slide" tag='div'>
                                        <div className="silder-img overlay-black-light">
                                            <img src={pooraka1} data-swiper-parallax="30%" alt=""/>
                                        </div>
                                        <div className="silder-content" data-swiper-parallax="-40%">
                                            <div className="dz-info">
                                                <h6 className="title m-b15"
                                                    style={{fontSize: '50px', lineHeight: '2.0'}}><Link
                                                    to="/portfolio-details">Pooraka
                                                    <br></br>
                                                    <span>SA 5095</span></Link></h6>
                                            </div>
                                            <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                        </div>
                                    </SwiperSlide>
                                }

                                {
                                    <SwiperSlide className="swiper-slide" tag='div'>
                                        <div className="silder-img overlay-black-light">
                                            <img src={pooraka2} data-swiper-parallax="30%" alt=""/>
                                        </div>
                                        <div className="silder-content" data-swiper-parallax="-40%">
                                            <div className="dz-info">
                                                <h6 className="title m-b15"
                                                    style={{fontSize: '50px', lineHeight: '2.0'}}><Link
                                                    to="/portfolio-details">Pooraka
                                                    <br></br>
                                                    <span>SA 5095</span></Link></h6>
                                            </div>
                                            <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                        </div>
                                    </SwiperSlide>
                                }
                            </Swiper>

                        </div>
                    </div>

                    <br></br>
                    <br></br>
                    <br></br>

                    <div className="dz-box overlay style-1"
                         style={{marginLeft: '10%', maxWidth: '1920px', maxHeight: '750px'}}>
                        <div className="silder-one">
                            <Swiper className="swiper-container main-silder-swiper"

                                    ref={swiperRef}
                                    slidesPerView={1}
                                    parallax={true}
                                    speed={2000}
                                    loop={true}
                                    autoplay={{delay: 3000}}
                                    pagination={{
                                        el: ".pagination-styled",
                                        type: "fraction",
                                    }}
                                    modules={[Pagination, Parallax, Navigation, Autoplay]}
                            >

                                {
                                    <SwiperSlide className="swiper-slide" tag='div'>
                                        <div className="silder-img overlay-black-light">
                                            <img src={clearView} data-swiper-parallax="30%" alt=""/>
                                        </div>
                                        <div className="silder-content" data-swiper-parallax="-40%">
                                            <div className="dz-info">
                                                <h6 className="title m-b15"
                                                    style={{fontSize: '50px', lineHeight: '2.0'}}><Link
                                                    to="/portfolio-details1">Clearview
                                                    <br></br>
                                                        <span>SA 5085</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={clearView1} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details1">Clearview
                                                        <br></br>
                                                        <span>SA 5085</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={clearView2} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details1">Clearview
                                                        <br></br>
                                                        <span>SA 5085</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={clearView3} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details1">Clearview
                                                        <br></br>
                                                        <span>SA 5085</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }
                                </Swiper>
                            </div>
                        </div>

                        <br></br>
                        <br></br>
                        <br></br>

                        <div className="dz-box overlay style-1"
                             style={{marginLeft: '10%', maxWidth: '1920px', maxHeight: '750px'}}>
                            <div className="silder-one">
                                <Swiper className="swiper-container main-silder-swiper"

                                        ref={swiperRef}
                                        slidesPerView={1}
                                        parallax={true}
                                        speed={2000}
                                        loop={true}
                                        autoplay={{delay: 3000}}
                                        pagination={{
                                            el: ".pagination-styled",
                                            type: "fraction",
                                        }}
                                        modules={[Pagination, Parallax, Navigation, Autoplay]}
                                >

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill1} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill2} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill3} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill4} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }

                                    {
                                        <SwiperSlide className="swiper-slide" tag='div'>
                                            <div className="silder-img overlay-black-light">
                                                <img src={magill5} data-swiper-parallax="30%" alt=""/>
                                            </div>
                                            <div className="silder-content" data-swiper-parallax="-40%">
                                                <div className="dz-info">
                                                    <h6 className="title m-b15" style={{ fontSize: '50px', lineHeight: '2.0' }}><Link to="/portfolio-details2">Magill
                                                        <br></br>
                                                        <span>SA 5072</span></Link></h6>
                                                </div>
                                                <div className="overlay-slide" data-swiper-parallax="100%"></div>
                                            </div>
                                        </SwiperSlide>
                                    }
                                </Swiper>
                            </div>
                        </div>


                </section>

                <section className="content-inner line-img overflow-hidden"
                         style={{marginLeft: '5%', marginRight: '10%'}}>
                    <div className="section-head style-1 text-center">
                        <h6 className="sub-title text-primary">OUR PROJECTS</h6>
                        <h2 className="title">Upcoming Projects</h2>
                    </div>


                    {

                        <motion.li className="card-container col-12 architecture m-b30" key={item1[1]}
                                   initial={{opacity: 0}}
                                   whileInView={{opacity: 1}}
                                   transition={{duration: 1}}
                                   style={{justifyContent: 'center', alignItems: 'center'}}
                        >
                            <div className="dz-box overlay style-1"
                                 style={{ marginLeft: '20%', maxWidth: '1500px', maxHeight: '750px'}}>
                                <div className="dz-media">
                                    <img src={darlington} alt=""
                                         style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
                                </div>
                                <div className="dz-info">
                                    <span data-exthumbimage="images/work/work-1/apic1.png"
                                          data-src="images/work/work-1/apic1.png" className="view-btn lightimg"></span>
                                    <h4 className="title m-b15"><Link>Darlington
                                        <br></br>
                                        <span>SA 5047</span></Link></h4>
                                </div>
                            </div>
                        </motion.li>}

                    {

                        <motion.li className="card-container col-12 architecture m-b30" key={item2[1]}
                                   initial={{opacity: 0}}
                                   whileInView={{opacity: 1}}
                                   transition={{duration: 1}}
                                   style={{justifyContent: 'center', alignItems: 'center'}}
                        >
                            <div className="dz-box overlay style-1"
                                 style={{marginLeft: '20%', maxWidth: '1500px', maxHeight: '750px'}}>
                                <div className="dz-media">
                                    <img src={vallyview} alt=""
                                         style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
                                </div>
                                <div className="dz-info">
                                    <span data-exthumbimage="images/work/work-1/apic1.png"
                                          data-src="images/work/work-1/apic1.png" className="view-btn lightimg"></span>
                                    <h4 className="title m-b15"><Link>Valley View
                                        <br></br>
                                        <span>SA 5093</span></Link></h4>
                                </div>
                            </div>
                        </motion.li>}


                    {

                        <motion.li className="card-container col-12 architecture m-b30" key={item2[1]}
                                   initial={{opacity: 0}}
                                   whileInView={{opacity: 1}}
                                   transition={{duration: 1}}
                                   style={{justifyContent: 'center', alignItems: 'center'}}
                        >
                            <div className="dz-box overlay style-1"
                                 style={{marginLeft: '20%', maxWidth: '1500px', maxHeight: '750px'}}>
                                <div className="dz-media">
                                    <img src={modburyHeights} alt=""
                                         style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
                                </div>
                                <div className="dz-info">
                                    <span data-exthumbimage="images/work/work-1/pic-3.jpg"
                                          data-src="images/work/work-1/pic-3.jpg" className="view-btn lightimg"></span>
                                    <h4 className="title m-b15"><Link>Modbury Heights
                                                <br></br>
                                                <span>SA 5092</span></Link></h4>
                                            </div>
                                        </div>
                                    </motion.li> }

                                    {
                                    
                                    <motion.li className="card-container col-12 architecture m-b30" key={item2[1]}
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                        style={{ justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <div className="dz-box overlay style-1" style={{ marginLeft: '20%', maxWidth: '1500px', maxHeight: '750px' }}>
                                            <div className="dz-media">
                                                <img src={geppeCross} alt="" style={{ width: '100%', height: 'auto', objectFit: 'cover' }}/>
                                            </div>
                                            <div className="dz-info">
                                                <span data-exthumbimage="images/work/work-1/pic-3.jpg" data-src="images/work/work-1/pic-3.jpg" className="view-btn lightimg"></span>
                                                <h4 className="title m-b15"><Link>Gepps Cross
                                                <br></br>
                                                <span>SA 5086</span></Link></h4>
                                            </div>
                                        </div>
                                    </motion.li> }

                                    {
                                    
                                    <motion.li className="card-container col-12 architecture m-b30" key={item2[1]}
                                        initial={{ opacity: 0 }}
                                        whileInView={{ opacity: 1 }}
                                        transition={{ duration: 1 }}
                                        style={{ justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <div className="dz-box overlay style-1" style={{ marginLeft: '20%', maxWidth: '1500px', maxHeight: '750px' }}>
                                            <div className="dz-media">
                                                <img src={ingleFarm} alt="" style={{ width: '100%', height: 'auto', objectFit: 'cover' }}/>
                                            </div>
                                            <div className="dz-info">
                                                <span data-exthumbimage="images/work/work-1/pic-3.jpg" data-src="images/work/work-1/pic-3.jpg" className="view-btn lightimg"></span>
                                                <h4 className="title m-b15"><Link>Ingle Farm
                                                <br></br>
                                                <span>SA 5098</span></Link></h4>
                                            </div>
                                        </div>
                                    </motion.li> }


                </section>

                
                {/* <section className="section-full dz-content-bx style-2 text-white" >
                    <Progress />
                </section> */}
                {/* <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Faq />
                </section> */}
            </div>
        </>
    )
}

export default Portfolio