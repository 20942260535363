import React, {useState, Fragment, useEffect} from 'react'
import { IMAGES } from '../constants/theme'
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { SocialIcon } from 'react-social-icons'
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import Companylogo from '../assets/images/newlogo05.png'
import wechat from '../assets/images/weChat.jpg'

//底部链接在这里

const Footer2 = () => {
	// State to control the visibility of the QR Code Modal
	const [showQR, setShowQR] = useState(false);

	// Function to toggle QR Code Modal
	const toggleQRModal = () => {
	  setShowQR(!showQR);
	};

	const [showPhone, setShowPhone] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	
	  const handleOutsideClick = (event) => {
		if (!event.target.closest('.icon-container')) {
		  setShowPhone(false);
		  setShowEmail(false);
		}
	  };
	
	  useEffect(() => {
		document.body.addEventListener('click', handleOutsideClick);
		return () => {
		  document.body.removeEventListener('click', handleOutsideClick);
		};
	  }, []);
	
	return (
		<>
			<footer className="site-footer style-1" id="footer">

				<div className="container" style={{padding:'40px'}}>
					<div className="row">
						<div className="col-lg-5 aos-item" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.0415146450982!2d138.6073984750736!3d-34.90540767349253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c93d21bc11eb%3A0xacefe376c63ebb42!2sUnit%203%2F22%20Melbourne%20St%2C%20North%20Adelaide%20SA%205006!5e0!3m2!1sen!2sau!4v1700006371526!5m2!1sen!2sau" className="align-self-stretch radius-sm" style={{ border: 0, width: '100%', minHeight: '100%' }} allowFullScreen></iframe>
						</div>
						<div style={{ width: '33.33%', padding: '20px', backgroundColor: '#323232', color: '#fff' }}>
        					<h3 style={{ color: '#a3cc02', fontSize: '45px', textAlign: 'center', whiteSpace: 'wrap', marginTop: '-30px', marginLeft: '0%' }}>Contact Us</h3>
        					<p style={{marginLeft: '20%',  whiteSpace: 'wrap', overflow: 'visible', textOverflow: 'ellipsis'}}>Unit 3/22 Melbourne St, North Adelaide SA 5006</p>
							<p style={{marginLeft: '20%',  whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'}}>+61 (08) 82579123</p>
							<p style={{marginLeft: '20%',  whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'}}>info@cyberbridge.com.au</p>
							<br></br>
							<br></br>
        					<h5 style={{color: '#fff', marginLeft: '20%', whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'}}>Office Hours</h5>
							<p style={{marginLeft: '20%', whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'}}>Monday to Friday</p>
							<p style={{marginLeft: '20%', whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'ellipsis'}}>9:00 am to 6:00 pm</p>
							<br></br>
							<h5 style={{color: '#fff', whiteSpace: 'nowrap', marginLeft: '20%',  overflow: 'visible', textOverflow: 'ellipsis'}}>Get Social</h5>

							<div className="icon-container"
								 style={{display: 'flex', alignItems: 'center', marginLeft:'20%'}}>
								<div style={{marginRight: '5%'}}>
									<SocialIcon url="https://www.linkedin.com/company/cyberidgeconstruction/" style={{
										bgColor: 'rgba(255, 255, 255, 0)',
										fgColor: 'white',
										height: 30,
										width: 30
									}}/>
								</div>
								<div style={{marginRight: '5%'}}>
									<SocialIcon url="www.wechat.com" onClick={(e) => {
										e.preventDefault();
										toggleQRModal();
									}} style={{
										backgroundColor: 'rgba(255, 255, 255, 0)',
										color: 'white',
										height: 30,
										width: 30
									}}/>
								</div>
									<div style={{marginRight: '5%'}}>
										<a href="tel:+61 (08) 82579123"><FaPhoneAlt
											style={{cursor: 'pointer', fontSize: '20px'}}/></a>
									</div>

									<div>
										<IoMdMail style={{cursor: 'pointer', fontSize: '25px', color: '#a3cc02'}}
												  onClick={() => window.location.href = '/contact-us'}/>
									</div>

								</div>

								{showQR && (
									<div style={{
										position: 'fixed',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										backgroundColor: 'rgba(0, 0, 0, 0.7)',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										zIndex: 1050
									}}>
										<div style={{
											position: 'relative',
											width: 'auto',
											maxWidth: '600px',
											padding: '20px',
											backgroundColor: '#fff',
											borderRadius: '8px',
											textAlign: 'center'
										}}>
											<img src={wechat} alt="Scan QR Code"
												 style={{maxWidth: '100%', height: 'auto'}}/>
											<button onClick={toggleQRModal} style={{
												position: 'absolute',
												top: '10px',
												right: '10px',
												border: 'none',
												background: '#FFF',
												fontSize: '30px',
												cursor: 'pointer',
												color: '#000',
												height: '30px',
												width: '30px',
												lineHeight: '30px',
												textAlign: 'center'
											}}>x
											</button>
										</div>
									</div>
								)}
								<img src={Companylogo} alt="Company Logo" style={{ marginLeft:'100%', marginTop:'-24%', width:'80%'}}/>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-6 text-center text-md-start">
								<span className="copyright-text">Copyright © 2025 <Link to="https://www.cyberidge.com.au/" className="text-primary" target="_blank">Cyberidge</Link> All rights reserved.</span>
							</div>
							<div className="col-md-6 text-center text-md-end">
								<ul className="footer-link d-inline-block">
									<li><Link>Privacy Policy</Link></li>
									<li><Link>Team & Condition</Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer2