import React, {useState} from "react";
import { IMAGES } from "../constants/theme";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import AvaliImage from '../assets/images/team/AvaLi.png';
import PeterXiaoImage from '../assets/images/team/PeterXiao.png';
import ReactCardFlip from 'react-card-flip';

const teamCard = [
    { teamImg: PeterXiaoImage, name: "Peter Xiao", skill: "Director", backGround: "Peter Xiao holds a Master's degree in Civil Engineering from Tongji University, a globally renowned institution consistently ranked among the top universities for Civil Engineering worldwide. He is a certified Civil and Structural Engineer, registered in Queensland, Victoria, and Tasmania. With extensive expertise in structural design, project management, and safety assurance, he is also a Licensed Building Supervisor (BLD291369), dedicated to maintaining the highest standards of quality in every project. As a lecturer at TAFE SA, Peter bridges academic knowledge with real-world experience, delivering innovative and sustainable construction solutions."},
    { teamImg: AvaliImage, name: "Ava Li", skill: "Civil Engineer", backGround: "Ava Li is a Civil Engineer with a Bachelor's degree from the University of South Australia. With both academic and professional experience gained locally, Ava has a deep understanding of the region's construction standards and practices. Her expertise in structural design and sustainable solutions ensures the delivery of high-quality projects tailored to local needs." },
];

function Team() {
    const [flips, setFlips] = useState(false);

    const handleClick = (index) => {
        setFlips(prevFlips => ({
            ...prevFlips,
            [index]: !prevFlips[index]
        }));
    };

    return (
        <div className="page-content bg-white" style = {{margin: '60px'}}>
            <Swiper
                spaceBetween={20}
                loop={true}
                slidesPerView={2}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false
                }}
                observer={true}
                observeParents={true}
                breakpoints={{
                    320: { // When the viewport is 320 pixels or greater
                        slidesPerView: 1
                    },
                    1024: { // When the viewport is 1024 pixels or greater
                        slidesPerView: 2
                    }
                }}
            >
                {teamCard.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="team-card" style={{ margin: '0 40px', width: 'auto', height: 'auto', marginLeft: '20%'}}>
                            <ReactCardFlip isFlipped={flips[index] || false} flipDirection="vertical">
                                <div className="card dz-team style-1" style={{marginBottom: '0%'}} onClick={() => handleClick(index)}>
                                    <div className="card-media">
                                        <img src={item.teamImg} alt={item.name} />
                                    </div>

                                    <div className="card-body" >
                                        <h5 className="dz-name m-b5">{item.name}</h5>
                                        <span className="dz-position">{item.skill}</span>
                                    </div>
                                </div>

                                <div className="card dz-team style-1" style={{marginTop: '0%', marginBottom: '0%'}}>
                                    <div onClick={() => handleClick(index)} style={{marginLeft:'20%', marginRight:'20%', marginTop: '5%'}}>
                                        <h4>Background Information</h4>
                                        <p style={{ textAlign: 'justify', textJustify: 'inter-word' }}>{item.backGround}</p>
                                    </div>

                                </div>
                            </ReactCardFlip>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default Team;
